
import Link from "next/link";
import React from "react";
import FormatedNumber from "../components/general/FormatedNumber";
import SatoshiToUsd from "../components/general/SatoshiToUsd";
import BaseLayout from "../components/layout/BaseLayout";
import TextContainer from "../components/layout/TextContainer";
import env from "../lib/env";
import CheckIcon from '@mui/icons-material/Check';
import UrlTiedNodeInputField from "../components/node/nav/UrlTiedNodeInputField";
import { SimpleNode } from "../components/general/SimpleNodeListContext";
import LoaderSpinner from "../components/general/LoadingSpinner";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import LandingPageSubscribeBtn from "../components/general/LandingPageSubscribeBtn";


export default function LandingPage() {
  const [node, setNode] = React.useState<SimpleNode>();
  const lowestPrice = Math.min(...env.pricing.map(p => p.monthlyPriceSat));

  return (
    <BaseLayout
      seoDescription="Opening profitable channels is a skill in itself. Use LnRouter to vet nodes. Allocate capital where it's needed."
      shareDescription="Opening profitable channels is a skill in itself. Use LnRouter to vet nodes. Allocate capital where it's needed."
      bottomMargin={false}
    >
      <div className="bg-gradient-to-b from-gradientLight to-viking-500 pt-16 text-gray-900 px-2">
        <div className="pt-8 pb-16">
          <h1 className="font-extrabold text-4xl md:text-6xl text-center leading-[1.1] mb-4 mt-12">
            Allocate Capital<br />
            Where It Is Useful
          </h1>
          <p className="text-center text-2xl px-2 pb-4">
            LnRouter helps node operators make <br />
            informed decisions with data that matters.
          </p>

          <div className=" pt-8">
            {!node &&
              <div>
                <UrlTiedNodeInputField size="big" className="mx-auto" onChange={value => setNode(value)}
                  style={{ boxShadow: '0px 2px 10px 4px rgba(8,43,43,0.56)' }}
                />
              </div>
            }

            {node &&
              <div>
                <div className="text-center font-bold text-2xl" style={{ marginBottom: '-20px' }}>
                  {node.label}
                </div>
                <LoaderSpinner width={50} />
              </div>
            }
          </div>
        </div>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#4adddf" fillOpacity="1" d="M0,128L16,144C32,160,64,192,96,208C128,224,160,224,192,213.3C224,203,256,181,288,181.3C320,181,352,203,384,181.3C416,160,448,96,480,90.7C512,85,544,139,576,154.7C608,171,640,149,672,170.7C704,192,736,256,768,250.7C800,245,832,171,864,144C896,117,928,139,960,170.7C992,203,1024,245,1056,256C1088,267,1120,245,1152,202.7C1184,160,1216,96,1248,101.3C1280,107,1312,181,1344,176C1376,171,1408,85,1424,42.7L1440,0L1440,0L1424,0C1408,0,1376,0,1344,0C1312,0,1280,0,1248,0C1216,0,1184,0,1152,0C1120,0,1088,0,1056,0C1024,0,992,0,960,0C928,0,896,0,864,0C832,0,800,0,768,0C736,0,704,0,672,0C640,0,608,0,576,0C544,0,512,0,480,0C448,0,416,0,384,0C352,0,320,0,288,0C256,0,224,0,192,0C160,0,128,0,96,0C64,0,32,0,16,0L0,0Z"></path>
      </svg>

      <div className="mt-12 mb-32 ">

        <div>
          <div className="">
            <div className="relative md:pt-12  mx-auto" style={{ maxWidth: '1000px' }}>

              <div className="flex justify-center z-20 mt-24 ">
                <img className="w-96 filter drop-shadow-xl rounded-xl z-20 " src="/imgs/lookups/liquidity_scan.png" />
              </div>
              <div className=" absolute left-0 z-10 top-0 hidden md:block" style={{ marginTop: '50px' }}>
                <img className="w-96 filter drop-shadow-xl  rounded-xl" src="/imgs/lookups/fee_distribution.png" />
              </div>

              <div className="absolute right-0 z-10 top-0  hidden md:block" style={{ marginTop: '50px' }}>
                <img className="w-96 filter drop-shadow-xl rounded-xl" src="/imgs/lookups/channel_balances.png" />
              </div>
            </div>
          </div>
          <div className="text-center pt-12 pb-8">
            <LandingPageSubscribeBtn size="big" />
            <p className="text-xs text-gray-400 mt-2">*Free trial for nodes with &gt; 5M sat public capacity.</p>
          </div>
        </div>

      </div>

      <div className="px-2 bg-gray-200">
        <TextContainer className="pt-32 pb-32 ">
          <h2 className="font-extrabold text-4xl text-center leading-[1.1] mb-4 ">
            An Attempt To Improve Routing
          </h2>
          <p className="text-center text-xl">
            Choosing the right routing partner is a skill in itself. Use LnRouter to vet nodes before you
            spend onchain fees to open channels with them.
          </p>
          <div className="flex justify-around pt-8">
            <div className=" rounded-md text-center p-4 bg-viking-900 text-white">
              <div className="text-2xl font-medium">
                <ArrowCircleUpIcon fontSize="inherit" className="text-4xl" />
              </div>
              Payment success
            </div>
            <div className=" rounded-md text-center p-4 bg-viking-900 text-white">
              <div className="text-2xl font-medium">
                10x
              </div>
              Faster payments*
            </div>
            <div className=" rounded-md text-center p-4 bg-viking-900 text-white">
              <div className="text-2xl font-medium">
                <ArrowCircleDownIcon fontSize="inherit" className="text-4xl" />
              </div>
              Stuck payments
            </div>
          </div>

        </TextContainer>
      </div>




      <div className="py-32 px-2 flex flex-col md:flex-row justify-center md:justify-around items-center text-center md:text-left bg-viking-1000 text-white">
        <img
          src="/imgs/lookups/routing_score2.png"
          className=" w-10/12 md:w-3/12 filter  drop-shadow-2xl rounded-xl md:ml-8 border-2" />
        <div className="pt-4" style={{ maxWidth: '500px' }}>
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 ">
            Identify Well-Managed Nodes
          </h2>
          <div className="text-xl text-gray-400">
            Connect to reliable routing partners to improve payment speed and reliability.
            A dependable routing partner manages its liquidity, responds quickly, and has channels to essential nodes in the network.
          </div>
        </div>
      </div>


      <div className="py-32 px-2 flex flex-col md:flex-row-reverse justify-center md:justify-around items-center text-center md:text-left bg-gray-200">
        <img
          src="/imgs/lookups/slow_dead_ends.png"
          className=" w-10/12 md:w-4/12 filter drop-shadow-2xl rounded-xl md:ml-8 border-2" />
        <div className="pt-8" style={{ maxWidth: '500px' }}>
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 ">
            Avoid Slow Dead-Ends
          </h2>
          <div className="text-xl text-gray-700">
            Slow peers with majorly imbalanced channels slow down payments significatly and
            can even increase the number of stuck payments. Improve your payment experience
            by connecting to the right peers.
          </div>
        </div>
      </div>

      <div className="py-32 px-2 bg-white">
        <div className="flex flex-col md:flex-row justify-center md:justify-around items-center text-center md:text-left">
          <img
            src="/imgs/graph-example2.jpg"
            className="w-10/12 md:w-4/12 filter drop-shadow-2xl rounded-xl ml-8 border-2" />
          <div className="pt-1" style={{ maxWidth: '500px' }}>
            <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 ">
              A Repository Of Hunderets Of Nodes
            </h2>
            <div className="text-xl text-gray-600">
              LnRouter scans and collects statistics of hunderets of nodes every month,
              giving you instant access to the most critical metrics.
            </div>
            <div className="pt-4">
            <LandingPageSubscribeBtn size="small" className="w-full" />
            </div>
          

          </div>
        </div>

      </div>

      <div className="py-32 px-2 ">
        <TextContainer>
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 text-center ">
            What Users Say
          </h2>
          <div className="flex justify-center">
            <div className="shadow-2xl border-2 rounded-xl py-8 px-8 bg-white" >
              <div className="text-sm italic" style={{ fontWeight: 300 }}>
                <p className="text-base font-semibold pb-2">
                  LnRouter has helped me transform from a recreational node to a profitable router.
                </p>
                <p className="text-gray-600" >
                  The insights I gained from LnRouter helped me keep my inbound sources of
                  liquidity balanced and flowing. <br />
                  This app is like accessing the dashboard of other nodes for a limited snapshot of their channels.
                  It's a must-have when choosing a new peer or attempting to improve rebalancing with an existing peer.
                </p>
              </div>

              <div className="flex items-center pt-8">
                <div>
                  <img
                    src="/imgs/lookups/azhodl.jpg"
                    className=" w-8 filter drop-shadow-2xl rounded-full border" />
                </div>
                <div>
                  <a className="font-bold pl-2 pr-2 hover:text-viking-700"
                    target="_blank"
                    href="https://azhodl.com/connect/">
                    AZHODL
                  </a>
                </div>
                <div>
                  <span className="text-gray-400 text-sm " style={{ fontWeight: 300 }}>Successful Lightning Network node operator</span>
                </div>
              </div>
            </div>
          </div>
        </TextContainer>
      </div>

      <div className="py-32 bg-viking-1000 text-white px-2 ">
        <TextContainer>
          <h2 className="font-extrabold text-3xl md:text-4xl leading-[1.1] mb-4 text-center ">
            Pricing
          </h2>
          <div className="flex justify-center">
            <div className="py-6 px-8 border drop-shadow-2xl rounded-xl bg-white text-black w-72">
              <p className="font-bold text-2xl mb-2 text-viking-700">PRO Plan</p>
              <p ><span className="text-2xl font-extrabold"><FormatedNumber value={lowestPrice} />sat</span>
                <span className="text-sm text-gray-400 font-normal"> = $<SatoshiToUsd satoshi={lowestPrice} decimals={1} /></span>
              </p>
              <p className="text-gray-400 text-xs">per month</p>

              <table className=" mt-6 text-sm font-light">
                <tbody>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Routing Score
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Actual Fee Distribution
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      HTLC Response Times
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CheckIcon color="inherit" fontSize="inherit" className="text-viking-500 mr-2 text-base" />
                    </td>
                    <td>
                      Unlimited Access
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="pt-8 text-center w-72 mx-auto">
            <LandingPageSubscribeBtn size="small" className="w-full" />
          </div>

        </TextContainer>

      </div>


      <div className="py-32 bg-gray-200 text-center text-xl px-2 ">

        <div className="pb-4 text-2xl font-bold">
          Explore The Lightning Network Now
        </div>

      </div>

    </BaseLayout>
  );
}
