import * as React from 'react'
import Link from 'next/link';
import ActionButton from './ActionButton';
import { useLnRouterTrial } from '../auth/plans/useLnRouterTrial';
import { useUserPlan } from '../auth/plans/useUserPlan';


interface Props {
    size: 'small' | 'big',
    className?: string
}


const LandingPageSubscribeBtn: React.FC<Props> = ({ size, className }: Props) => {
    const trial = useLnRouterTrial();
    const plan = useUserPlan();

    const spanClass = size === 'big' ? 'text-2xl py-1' : 'text-lg';

    if (plan.plan === 'ANONYMOUS') {
        return <Link href="/auth/login">
                <a>
                    <ActionButton className={"bg-gradient-to-r from-yellow-600 to-yellow-400 hover:from-yellow-700 hover:to-yellow-500 " + className}>
                        <span className={'px-4 text-light capitalize ' + spanClass}>Start free trial</span>
                    </ActionButton>
                </a>
            </Link>;
    }
    if (plan.plan === 'SUBSCRIBED') {
        return <React.Fragment />
    }
    if (!trial.available) {
        return <Link href="/account/plan">
            <a>
                <ActionButton className={"bg-gradient-to-r from-yellow-600 to-yellow-400 hover:from-yellow-700 hover:to-yellow-500 " + className}>
                    <span className={'px-4 text-light capitalize ' + spanClass}>Purchase plan</span>
                </ActionButton>
            </a>
        </Link>
    } else {
        return <Link href="/explore">
            <a>
                <ActionButton className={"bg-gradient-to-r from-yellow-600 to-yellow-400 hover:from-yellow-700 hover:to-yellow-500 " + className}>
                    <span className={'px-4 text-light capitalize ' + spanClass}>Start free trial</span>
                </ActionButton>
            </a>
        </Link>
    }
}

export default LandingPageSubscribeBtn;


